exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-creative-breakthrough-3-d-pen-tsx": () => import("./../../../src/pages/CreativeBreakthrough/3dPen.tsx" /* webpackChunkName: "component---src-pages-creative-breakthrough-3-d-pen-tsx" */),
  "component---src-pages-creative-breakthrough-architecture-chess-tsx": () => import("./../../../src/pages/CreativeBreakthrough/architectureChess.tsx" /* webpackChunkName: "component---src-pages-creative-breakthrough-architecture-chess-tsx" */),
  "component---src-pages-creative-breakthrough-collect-p-tsx": () => import("./../../../src/pages/CreativeBreakthrough/collectP.tsx" /* webpackChunkName: "component---src-pages-creative-breakthrough-collect-p-tsx" */),
  "component---src-pages-creative-breakthrough-graffiti-vr-tsx": () => import("./../../../src/pages/CreativeBreakthrough/graffitiVR.tsx" /* webpackChunkName: "component---src-pages-creative-breakthrough-graffiti-vr-tsx" */),
  "component---src-pages-creative-breakthrough-perm-game-tsx": () => import("./../../../src/pages/CreativeBreakthrough/permGame.tsx" /* webpackChunkName: "component---src-pages-creative-breakthrough-perm-game-tsx" */),
  "component---src-pages-creative-breakthrough-sail-tsx": () => import("./../../../src/pages/CreativeBreakthrough/sail.tsx" /* webpackChunkName: "component---src-pages-creative-breakthrough-sail-tsx" */),
  "component---src-pages-creative-breakthrough-tsx": () => import("./../../../src/pages/CreativeBreakthrough.tsx" /* webpackChunkName: "component---src-pages-creative-breakthrough-tsx" */),
  "component---src-pages-experimental-wave-autonomous-capsules-tsx": () => import("./../../../src/pages/ExperimentalWave/autonomousCapsules.tsx" /* webpackChunkName: "component---src-pages-experimental-wave-autonomous-capsules-tsx" */),
  "component---src-pages-experimental-wave-chistomam-tsx": () => import("./../../../src/pages/ExperimentalWave/chistomam.tsx" /* webpackChunkName: "component---src-pages-experimental-wave-chistomam-tsx" */),
  "component---src-pages-experimental-wave-milking-mashine-tsx": () => import("./../../../src/pages/ExperimentalWave/milkingMashine.tsx" /* webpackChunkName: "component---src-pages-experimental-wave-milking-mashine-tsx" */),
  "component---src-pages-experimental-wave-robot-assistant-tsx": () => import("./../../../src/pages/ExperimentalWave/robotAssistant.tsx" /* webpackChunkName: "component---src-pages-experimental-wave-robot-assistant-tsx" */),
  "component---src-pages-experimental-wave-smart-home-tsx": () => import("./../../../src/pages/ExperimentalWave/smartHome.tsx" /* webpackChunkName: "component---src-pages-experimental-wave-smart-home-tsx" */),
  "component---src-pages-experimental-wave-tsx": () => import("./../../../src/pages/ExperimentalWave.tsx" /* webpackChunkName: "component---src-pages-experimental-wave-tsx" */),
  "component---src-pages-extraordinary-thoughts-eco-battery-tsx": () => import("./../../../src/pages/ExtraordinaryThoughts/ecoBattery.tsx" /* webpackChunkName: "component---src-pages-extraordinary-thoughts-eco-battery-tsx" */),
  "component---src-pages-extraordinary-thoughts-fry-autofeeder-tsx": () => import("./../../../src/pages/ExtraordinaryThoughts/fryAutofeeder.tsx" /* webpackChunkName: "component---src-pages-extraordinary-thoughts-fry-autofeeder-tsx" */),
  "component---src-pages-extraordinary-thoughts-green-matryoshka-tsx": () => import("./../../../src/pages/ExtraordinaryThoughts/greenMatryoshka.tsx" /* webpackChunkName: "component---src-pages-extraordinary-thoughts-green-matryoshka-tsx" */),
  "component---src-pages-extraordinary-thoughts-med-drone-tsx": () => import("./../../../src/pages/ExtraordinaryThoughts/medDrone.tsx" /* webpackChunkName: "component---src-pages-extraordinary-thoughts-med-drone-tsx" */),
  "component---src-pages-extraordinary-thoughts-mrt-tsx": () => import("./../../../src/pages/ExtraordinaryThoughts/mrt.tsx" /* webpackChunkName: "component---src-pages-extraordinary-thoughts-mrt-tsx" */),
  "component---src-pages-extraordinary-thoughts-self-cleaning-handle-tsx": () => import("./../../../src/pages/ExtraordinaryThoughts/selfCleaningHandle.tsx" /* webpackChunkName: "component---src-pages-extraordinary-thoughts-self-cleaning-handle-tsx" */),
  "component---src-pages-extraordinary-thoughts-tsx": () => import("./../../../src/pages/ExtraordinaryThoughts.tsx" /* webpackChunkName: "component---src-pages-extraordinary-thoughts-tsx" */),
  "component---src-pages-genius-ideas-adapter-tsx": () => import("./../../../src/pages/GeniusIdeas/adapter.tsx" /* webpackChunkName: "component---src-pages-genius-ideas-adapter-tsx" */),
  "component---src-pages-genius-ideas-antenna-analyzer-tsx": () => import("./../../../src/pages/GeniusIdeas/antennaAnalyzer.tsx" /* webpackChunkName: "component---src-pages-genius-ideas-antenna-analyzer-tsx" */),
  "component---src-pages-genius-ideas-anti-vandal-handle-tsx": () => import("./../../../src/pages/GeniusIdeas/antiVandalHandle.tsx" /* webpackChunkName: "component---src-pages-genius-ideas-anti-vandal-handle-tsx" */),
  "component---src-pages-genius-ideas-ecospoon-tsx": () => import("./../../../src/pages/GeniusIdeas/ecospoon.tsx" /* webpackChunkName: "component---src-pages-genius-ideas-ecospoon-tsx" */),
  "component---src-pages-genius-ideas-humidifier-tsx": () => import("./../../../src/pages/GeniusIdeas/humidifier.tsx" /* webpackChunkName: "component---src-pages-genius-ideas-humidifier-tsx" */),
  "component---src-pages-genius-ideas-nyam-nyam-tsx": () => import("./../../../src/pages/GeniusIdeas/nyamNyam.tsx" /* webpackChunkName: "component---src-pages-genius-ideas-nyam-nyam-tsx" */),
  "component---src-pages-genius-ideas-tsx": () => import("./../../../src/pages/GeniusIdeas.tsx" /* webpackChunkName: "component---src-pages-genius-ideas-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sparkling-innovations-cat-fountain-tsx": () => import("./../../../src/pages/SparklingInnovations/cat_fountain.tsx" /* webpackChunkName: "component---src-pages-sparkling-innovations-cat-fountain-tsx" */),
  "component---src-pages-sparkling-innovations-fracture-fixator-tsx": () => import("./../../../src/pages/SparklingInnovations/fracture_fixator.tsx" /* webpackChunkName: "component---src-pages-sparkling-innovations-fracture-fixator-tsx" */),
  "component---src-pages-sparkling-innovations-gears-tsx": () => import("./../../../src/pages/SparklingInnovations/gears.tsx" /* webpackChunkName: "component---src-pages-sparkling-innovations-gears-tsx" */),
  "component---src-pages-sparkling-innovations-grate-tsx": () => import("./../../../src/pages/SparklingInnovations/grate.tsx" /* webpackChunkName: "component---src-pages-sparkling-innovations-grate-tsx" */),
  "component---src-pages-sparkling-innovations-smart-backpack-tsx": () => import("./../../../src/pages/SparklingInnovations/smart_backpack.tsx" /* webpackChunkName: "component---src-pages-sparkling-innovations-smart-backpack-tsx" */),
  "component---src-pages-sparkling-innovations-smart-charger-tsx": () => import("./../../../src/pages/SparklingInnovations/smart_charger.tsx" /* webpackChunkName: "component---src-pages-sparkling-innovations-smart-charger-tsx" */),
  "component---src-pages-sparkling-innovations-tsx": () => import("./../../../src/pages/SparklingInnovations.tsx" /* webpackChunkName: "component---src-pages-sparkling-innovations-tsx" */),
  "component---src-pages-sparkling-innovations-vestibular-trainer-tsx": () => import("./../../../src/pages/SparklingInnovations/vestibular_trainer.tsx" /* webpackChunkName: "component---src-pages-sparkling-innovations-vestibular-trainer-tsx" */)
}

